<script>
    import { createEventDispatcher } from 'svelte';
    import Bar from './bar.svelte';
    import Button from './button.svelte';

    export let movecount = 0;

    const dispatch = createEventDispatcher();
</script>

<style>
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.66);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .box {
        padding: 32px;
        border-radius: 16px;
        color: black;
        background-color: #e0e0e0;
        box-shadow: 2px 2px 4px black;
    }
    .content {
        position: relative;
        width: 240px;
        height: 120px;
        font-size: 24px;
        text-align: center;
    }
</style>

<div class="overlay">
    <div class="box">
        <div class="content">
            <div>Moves: {movecount}</div>
            <Bar horizontal bottom center>
                <Button on:click="{() => dispatch('reset')}">
                    <img src="icons/reset.png" alt="Restart">
                </Button>
                <Button on:click="{() => dispatch('menu')}">
                    <img src="icons/menu.png" alt="Menu">
                </Button>
                <Button on:click="{() => dispatch('next')}">
                    <img src="icons/next.png" alt="Next">
                </Button>
            </Bar>
        </div>
    </div>
</div>