<script>
	import Game from './game.svelte';
	import Menu from './menu.svelte';
	import Bar from './bar.svelte';
	import Button from './button.svelte';
	import screenfull from 'screenfull';

	let game;
	let menu;
	let levelset = null;
	let level = null;
	let playing = false;
	let fullscreen = false;

	function onMenuSelect(event) {
		if (!level || event.detail.levelset != level.levelset || event.detail.index != level.index) {
			level = event.detail;
			const id = level.levelset+':'+level.index.toString();
			let snapshot = null;
			if (localStorage.getItem('current') == id) {
				snapshot = localStorage.getItem('snapshot');
			}
			localStorage.setItem('current', id);
			game.start(level.data, snapshot);
		}
		playing = true;
	}

	function onGameStart() {
		playing = true;
	}

	function onGameExit() {
		playing = false;
	}

	function onGameComplete(event) {
		menu.complete(level.levelset, level.index, event.detail.movecount);
	}

	function onGameNext() {
		playing = menu.next(level.levelset, level.index);
	}

	screenfull.on('change', () => {
		fullscreen = screenfull.isFullscreen;
	});
</script>

<style>
	.overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
		right: 0;
	}
	.menu {
		overflow: auto;
		background-color: #80d0f0;
		display: none;
	}
	.menu-buttons {
		pointer-events: none;
		display: none;
	}
	.icon {
        width: 32px;
        height: 32px;
    }
	.active {
		display: block;
	}
</style>

<div class="game overlay">
	<Game bind:this="{game}"
		on:start="{onGameStart}"
		on:exit="{onGameExit}"
		on:complete="{onGameComplete}"
		on:next="{onGameNext}"/>
</div>

<div class="menu overlay" class:active="{!playing}">
	<Menu bind:this="{menu}"
		current="{levelset}"
		currentlevel="{level}"
		on:select="{onMenuSelect}"/>
</div>


<div class="menu-buttons overlay" class:active="{!playing}">
	<Bar horizontal top left>
		{#if level}
			<Button on:click="{() => {playing = true;}}">
				<img class="icon" src="icons/resume.png" alt="Resume">
			</Button>
		{/if}
	</Bar>
	<Bar horizontal top right>
		{#if level}
			<Button on:click="{() => {game.reset(); playing = true;}}">
				<img class="icon" src="icons/reset.png" alt="Restart">
			</Button>
		{/if}
		{#if screenfull.isEnabled}
			<Button on:click="{() => {screenfull.toggle();}}">
				{#if fullscreen}
					<img class="icon" src="icons/reduce.png" alt="Reduce">
				{:else}
					<img class="icon" src="icons/expand.png" alt="Expand">
				{/if}
			</Button>
		{/if}
	</Bar>
</div>