<script>
    import { createEventDispatcher } from 'svelte';

    export let active = true;
    let timeout = null;

    const dispatch = createEventDispatcher();

    function onPointerDown(event) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        dispatch('pointerdown', event);
        timeout = setTimeout(() => onPointerRepeat(event), 500);
    }

    function onPointerRepeat(event) {
        dispatch('pointerdown', event);
        timeout = setTimeout(() => onPointerRepeat(event), 150);
    }

    function onPointerUp(event) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    }
</script>

<style>
    .button {
        position: relative;
        display: inline-block;
        margin: 8px;
        padding: 12px;
        width: 56px;
        height: 56px;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        color: white;
        background-color: #60c060;
        border: none;
        box-shadow: inset -4px -4px 0 0 #40a040;
        outline: none;
        pointer-events: none;
        user-select: none;
    }
    .button:before,
    .button:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .button:before {
        top: -4px;
        left: 0;
        border-top: 4px solid black;
        border-bottom: 4px solid black;
    }
    .button:after {
        top: 0;
        left: -4px;
        border-left: 4px solid black;
        border-right: 4px solid black;
    }
    .active {
        pointer-events: auto;
    }
</style>

<button class="button"
    class:active="{active}"
    on:click|preventDefault
    on:pointerdown="{onPointerDown}"
    on:pointerup="{onPointerUp}"
    on:pointerout="{onPointerUp}"
    on:pointerleave="{onPointerUp}"
    on:pointercancel="{onPointerUp}"
    on:contextmenu|preventDefault="{() => false}">
        <slot></slot>
</button>