<script>
    export let horizontal = false;
    export let vertical = false;
    export let top = false;
    export let bottom = false;
    export let left = false;
    export let right = false;
    export let center = false;
</script>

<style>
    .bar {
        position: absolute;
        display: flex;
        align-items: flex-start;
        margin: 4px;
        pointer-events: none;
    }
    .bar :global(*) {
        pointer-events: auto;
    }
    .bar.top {
        top: 0;
        justify-content: flex-start;
    }
    .bar.left {
        left: 0;
        justify-content: flex-start;
    }
    .bar.bottom {
        bottom: 0;
        justify-content: flex-end;
    }
    .bar.right {
        right: 0;
        justify-content: flex-end;
    }
    .bar.center.horizontal {
        left: 0;
        right: 0;
        justify-content: center;
    }
    .bar.center.vertical {
        top: 0;
        bottom: 0;
        align-items: center;
    }
    .bar.horizontal {
        flex-direction: row;
    }
    .bar.vertical {
        flex-direction: column;
    }
</style>

<div class="bar"
    class:horizontal="{horizontal}"
    class:vertical="{vertical}"
    class:top="{top}"
    class:bottom="{bottom}"
    class:left="{left}"
    class:right="{right}"
    class:center="{center}">
        <slot></slot>
</div>