<script>
    import { createEventDispatcher } from 'svelte';
    import Level from './level.svelte';

    export let id = '';
    export let levels = [];
    export let generator = null;
    export let currentlevel = null;

    const dispatch = createEventDispatcher();

    function onSelect(event) {
        dispatch('select', {
            ...event.detail,
            levelset: id
        });
    }
</script>

<style>
</style>

<div class="levelset">
    {#if levels && levels.length > 0}
        {#each levels as level, index}
            <Level
                index="{index}"
                data="{level.data}"
                unlocked="{level.unlocked}"
                current="{currentlevel == index}"
                on:select="{onSelect}" />
        {/each}
    {:else if generator}
        <Level generator="{generator}" unlocked on:select="{onSelect}" />
    {/if}
</div>