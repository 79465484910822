<script>
	import { createEventDispatcher, onMount } from 'svelte';
	import LevelSet from './levelset.svelte';

	import { generateLevel } from './generate.js';

	// import TestLevelSet from '../resources/levels/test.json';
	import OriginalLevelSet from '../resources/levels/original.json';
	import ExtraLevelSet from '../resources/levels/extra.json';
	import PerfectLevelSet from '../resources/levels/perfect.json';
	import RevengeLevelSet from '../resources/levels/revenge.json';
	import Boxxle1LevelSet from '../resources/levels/boxxle1.json';
	import Boxxle2LevelSet from '../resources/levels/boxxle2.json';
	import BoxworldLevelSet from '../resources/levels/boxworld.json';

	const dispatch = createEventDispatcher();

	export let current = 'original';
	export let currentlevel = null;

	let levelsets = [
		// {
		// 	id: 'test',
		// 	title: 'Test',
		// 	levels: TestLevelSet
		// },
		{
			id: 'original',
			title: 'Original',
			levels: OriginalLevelSet
		},
		{
			id: 'extra',
			title: 'Extra',
			levels: ExtraLevelSet
		},
		{
			id: 'perfect',
			title: 'Perfect',
			levels: PerfectLevelSet
		},
		{
			id: 'revenge',
			title: 'Revenge',
			levels: RevengeLevelSet
		},
		{
			id: 'boxxle1',
			title: 'Boxxle I',
			levels: Boxxle1LevelSet
		},
		{
			id: 'boxxle2',
			title: 'Boxxle II',
			levels: Boxxle2LevelSet
		},
		{
			id: 'boxworld',
			title: 'Boxworld',
			levels: BoxworldLevelSet
		},
		{
			id: 'random',
			title: 'Random',
			generator: generateLevel
		}
	];

	const storageCurrent = localStorage.getItem('current');
	if (storageCurrent) {
		const p = storageCurrent.split(':');
		if (p.length == 2) {
			current = p[0];
			const index = parseInt(p[1]);
			currentlevel = levelsets.find(l => l.id == current).levels[index];
			currentlevel.index = index;
			currentlevel.levelset = current;
		}
	}

	export function complete(id, index, score) {
		const set = levelsets.find(item => item.id == id);
		if (set && set.levels && index < set.levels.length) {
			const level = set.levels[index];
			const key = id+':'+(index+1).toString();

			localStorage.setItem(key, score.toString());
			level.score = score;
			level.complete = true;

			if (index+1 < set.levels.length) {
				set.levels[index+1].unlocked = true;
			}
			// Updates UI
			levelsets = levelsets;
		}
	}

	export function next(id, index) {
		const set = levelsets.find(item => item.id == id);
		if (set && set.levels && index+1 < set.levels.length) {
			const level = set.levels[index+1];
			dispatch('select', {
				levelset: id,
				index: index+1,
				data: level.data
			});
			return true;
		} else if (set && set.generator) {
			dispatch('select', {
				levelset: id,
				index: 0,
				data: set.generator()
			});
			return true;
		}
		return false;
	}

	// Setup unlocked levels based on local storage data
	levelsets.forEach(levelset => {
		if (levelset.levels) {
			levelset.levels.forEach((level, index) => {
				const key = levelset.id+':'+(index+1).toString();
				const score = localStorage.getItem(key);
				if (index == 0) {
					level.unlocked = true;
				}
				if (score) {
					level.score = score;
					level.complete = true;
					if (index+1 < levelset.levels.length) {
						levelset.levels[index+1].unlocked = true;
					}
				}
			});
		}
	});
</script>

<style>
	.menu {
		position: relative;
		margin: 32px auto;
		width: 360px;
		text-align: center;
	}
	.title {
		color: #408040;
		font-weight: bold;
		font-size: 48px;
		margin: 80px 8px 8px 8px;
	}
	.select {
		margin: 8px;
		position: relative;
		display: inline-block;
	}
	.select select {
		font-size: 24px;
		color: white;
		background: #604000;
		cursor: pointer;
		padding: 8px 32px 8px 24px;
		border: solid 8px #408040;
		transition: box-shadow .5s ease;
		outline: none;
	}
	.select:after {
		content: "▼";
		position: absolute;
		top: 8px;
		right: 8px;
		bottom: 8px;
		font-size: 24px;
		line-height: 48px;
		padding: 0 16px 0 0;
		background: #604000;
		color: white;
		pointer-events: none;
	}
	.select select:hover {
		box-shadow: 4px 4px 8px gray;
	}
	.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: auto;
		width: 320px;
		padding: 32px 16px;
		background: #f0f0d8;
		border: solid 4px #404040;
	}
</style>

<div class="menu">
	<div class="title">S0k0ban</div>
	<div class="select">
		<select on:change="{event => {current = event.target.value;}}">
			{#each levelsets as levelset, i}
				<option value="{levelset.id}" selected="{(current && current == levelset.id) || (!current && i == 0)}">{levelset.title}</option>
			{/each}
		</select>
	</div>
		{#each levelsets as levelset, i}
			{#if (current && current == levelset.id) || (!current && i == 0)}
				<div class="list">
					<LevelSet
						id="{levelset.id}"
						levels="{levelset.levels}"
						generator="{levelset.generator}"
						currentlevel="{(currentlevel && currentlevel.levelset == levelset.id) ? currentlevel.index : null}"
						on:select/>
				</div>
			{/if}
		{/each}
</div>