/**
 * Generate a random level.
 * 
 * Usage: node generate.js
 */

class State {

    /**
     * Constructor.
     */
    constructor(parent = null) {
        if (parent) {
            // Deep copy of the parent
            this.board = {...parent.board };
            this.boxes = parent.boxes.map(v => { return {...v }; });
            this.mover = {...parent.mover };
            this.minX = parent.minX;
            this.minY = parent.minY;
            this.maxX = parent.maxX;
            this.maxY = parent.maxY;
            this.moves = parent.moves.slice(0);
        } else {
            // Explored cells
            this.board = { '0,0': '-' };

            // List of created boxes
            this.boxes = [];

            // Current position of the mover
            this.mover = { x: 0, y: 0 };

            // Bounds of the board
            this.minX = 0;
            this.minY = 0;
            this.maxX = 0;
            this.maxY = 0;

            // All the previous moves
            this.moves = [];
        }

        // List of visited directions
        this.visited = [];

        // Contains the indices of the boxes that are currently pushable
        this.push = {};
    }

    /**
     * Return the last move, null otherwise.
     */
    get lastMove() {
        return this.moves.length > 0 ? this.moves[this.moves.length - 1] : null;
    }

    /**
     * Return the number of moves.
     */
    get moveCount() {
        return this.moves.length;
    }

    /**
     * Return the number of boxes.
     */
    get boxCount() {
        return this.boxes.length;
    }

    /**
     * Return the number of cells.
     */
    get cellCount() {
        return Object.keys(this.board).length;
    }

    /**
     * Return the width of the board.
     */
    get width() {
        return 1 + this.maxX - this.minX;
    }

    /**
     * Return the height of the board.
     */
    get height() {
        return 1 + this.maxY - this.minY;
    }

    /**
     * Return the solution.
     */
    get solution() {
        return this.moves.slice(0).reverse().join('');
    }

    /**
     * Check if a cell exists at given position.
     * @param pos A {x,y} object representing the position.
     */
    cellExists(pos) {
        return this.board.hasOwnProperty(this._k(pos));
    }

    /**
     * Check if a box exists a given position.
     * @param pos A {x,y} object representing the position.
     */
    boxExists(pos) {
        return this.findBox(pos) != -1;
    }

    /**
     * Find the index of the box at given position.
     * @param pos A {x,y} object representing the position.
     */
    findBox(pos) {
        return this.boxes.findIndex(box => box.x == pos.x && box.y == pos.y);
    }

    /**
     * Check if a move is a push.
     * @param dir The direction of the move.
     */
    isMovePush(dir) {
        return dir == dir.toUpperCase();
    }

    /**
     * Check if a move is redundant (i.e. goes backward).
     * @param dir The direction of the move.
     */
    isMoveRedundant(dir) {

        switch (this.lastMove) {
            case 'u':
                return dir == 'd';
            case 'd':
                return dir == 'u';
            case 'l':
                return dir == 'r';
            case 'r':
                return dir == 'l';
        }
        return false;
    }

    /**
     * Check if a move is feasible.
     * @param dir The direction of the move.
     */
    isMoveFeasible(dir) {

        // No box should be on previous position
        const prev = this.backward(this.mover, dir);
        if (this.boxExists(prev)) {
            return false;
        }

        // When move is a push
        if (this.isMovePush(dir)) {
            const dest = this.forward(this.mover, dir);
            // If a cell exists, there must be a box
            if (this.cellExists(dest)) {
                const index = this.findBox(dest);
                if (index == -1) {
                    return false;
                } else {
                    this.push[dir] = index;
                }
            }
        }
        return true;
    }

    /**
     * Create a new cell.
     * @param pos A {x,y} object representing the position.
     * @param type Type of cell.
     */
    createCell(pos, type) {
        this.board[this._k(pos)] = type;

        if (pos.x < this.minX) {
            this.minX = pos.x;
        }
        if (pos.y < this.minY) {
            this.minY = pos.y;
        }
        if (pos.x > this.maxX) {
            this.maxX = pos.x;
        }
        if (pos.y > this.maxY) {
            this.maxY = pos.y;
        }
    }

    /**
     * Create a new box and a goal cell.
     * @param pos A {x,y} object representing the position of the box.
     */
    createBox(pos) {
        const box = {
            ...pos,
            goal: {...pos }
        };
        this.boxes.push(box);
        this.createCell(pos, '.');
        return box;
    }

    /**
     * Return a list of candidate moves.
     * 
     * Candidate moves are feasible, non-visited and non-redundant.
     */
    candidateMoves() {
        if (this.moves.length > 0) {
            return ['u', 'd', 'l', 'r', 'U', 'D', 'L', 'R'].filter(dir =>
                !this.isMoveRedundant(dir) &&
                !this.visited.includes(dir) &&
                this.isMoveFeasible(dir)
            );
        } else {
            // Win move must be a push
            return ['U', 'D', 'L', 'R'];
        }
    }

    /**
     * Apply a given move and return a copy of the new state.
     * @param dir The direction of the move.
     */
    applyMove(dir) {
        const state = new State(this);
        state.applyMoveInline(dir);
        this.visited.push(dir);
        return state;
    }

    /**
     * Apply a given move to the current state. The state will be modified.
     * @param dir The direction of the move.
     */
    applyMoveInline(dir) {

        const prev = this.backward(this.mover, dir);

        // Cell not found yet, create a floor
        if (!this.cellExists(prev)) {
            this.createCell(prev, '-');
        }

        // When the move is a push
        if (this.isMovePush(dir)) {

            // Find the box to move
            let box;

            const dest = this.forward(this.mover, dir);

            if (this.cellExists(dest)) {
                // Push an existing box
                if (this.push.hasOwnProperty(dir)) {
                    box = this.boxes[this.push[dir]]
                } else {
                    const dest = this.forward(this.mover, dir);
                    const index = this.findBox(dest);
                    if (index != -1) {
                        box = this.boxes[index];
                    } else {
                        throw new Error('applyMove: Box not found');
                    }
                }
            } else {
                // Create a box and a goal cell
                box = this.createBox(dest);
            }

            box.x = this.mover.x;
            box.y = this.mover.y;
        }

        // Update the position of the mover
        this.mover.x = prev.x;
        this.mover.y = prev.y;

        // Reset properties that are not valid in the new position
        this.push = {};
        this.visited = [];

        // Add the move to the collection
        this.moves.push(dir);
    }

    /**
     * Return the level in XSB format.
     * @returns A multiline string representing the level.
     */
    toXSB() {
        // Create a 2D array
        let matrix = Array.from(Array(2 + this.height), () => Array.from(Array(2 + this.width), () => '#'));

        // Modify every cell that is not wall
        Object.keys(this.board).forEach(key => {
            // Board position (can have negative values)
            const pos = this._p(key);

            // Normalized position
            const x = 1 - this.minX + pos.x;
            const y = 1 - this.minY + pos.y;

            if (this.mover.x == pos.x && this.mover.y == pos.y) {
                // Found mover
                switch (this.board[key]) {
                    case '-':
                        matrix[y][x] = '@';
                        break;
                    case '.':
                        matrix[y][x] = '+';
                        break;
                }
            } else if (this.boxExists(pos)) {
                // Found box
                switch (this.board[key]) {
                    case '-':
                        matrix[y][x] = '$';
                        break;
                    case '.':
                        matrix[y][x] = '*';
                        break;
                }
            } else {
                matrix[y][x] = this.board[key] == '-' ? ' ' : this.board[key];
            }
        });

        return matrix.map(row => row.join('')).join('\n');
    }

    /**
     * Translate position coordinates, forward, according to a direction.
     * @param pos A {x,y} object.
     * @param dir A direction.
     * @returns The translated position.
     */
    forward(pos, dir) {
        switch (dir.toLowerCase()) {
            case 'u':
                return { x: pos.x, y: pos.y - 1 };
            case 'd':
                return { x: pos.x, y: pos.y + 1 };
            case 'l':
                return { x: pos.x - 1, y: pos.y };
            case 'r':
                return { x: pos.x + 1, y: pos.y };
        }
        return pos;
    }

    /**
     * Translate position coordinates, backward, according to a direction.
     * @param pos A {x,y} object.
     * @param dir A direction.
     * @returns The translated position.
     */
    backward(pos, dir) {
        switch (dir.toLowerCase()) {
            case 'u':
                return { x: pos.x, y: pos.y + 1 };
            case 'd':
                return { x: pos.x, y: pos.y - 1 };
            case 'l':
                return { x: pos.x + 1, y: pos.y };
            case 'r':
                return { x: pos.x - 1, y: pos.y };
        }
        return pos;
    }

    /**
     * Convert a position to a string.
     * @param pos A {x,y} object.
     * @returns A string representing the position.
     */
    _k(pos) {
        return pos.x.toString() + ',' + pos.y.toString();
    }

    /**
     * Convert a string to a position.
     * @param key A string.
     * @returns A {x,y} object representing the position.
     */
    _p(key) {
        const p = key.split(',');
        return { x: parseInt(p[0]), y: parseInt(p[1]) };
    }

    /**
     * Encode a string to RLE format.
     */
    encodeRLE(input) {
        let output = '';
        const match = input.match(/(.)\1*/g);
        if (match !== null) {
            match.forEach(s => {
                if (s.length > 1) {
                    output += s.length.toString();
                }
                output += s[0];
            });
        }
        return output;
    }

    /**
     * Decode a RLE string.
     */
    decodeRLE(input) {
        let output = input.replace(/([0-9]+)([^0-9])/g,
            (match, p1, p2) => {
                return p2.repeat(parseInt(p1));
            });
        return output;
    }
}

function generate(options) {

    // Default options
    const opt = {
        acceptSolution: (state) => true,
        acceptMove: (dir, state) => true,
        selectMove: (dirs) => dirs[~~(dirs.length * Math.random())],
        maxIterations: Number.MAX_SAFE_INTEGER,
        ...options
    };

    // Count the number of iterations
    let iteration = 0;

    // Backtracking stack
    const stack = [];

    // The current state
    let state = new State();

    // The last (win) move has to be a push
    const candidates = state.candidateMoves();
    const dir = opt.selectMove(candidates, state);

    state = state.applyMove(dir);
    iteration++;

    // While requirement is not met
    while (!opt.acceptSolution(state) && iteration < opt.maxIterations) {

        if (options.debugIterations && iteration % options.debugIterations == 0) {
            console.log('');
            console.log('Iteration:', iteration)
            console.log(state.toXSB());
            console.log(state.solution);
        }

        const candidates = state.candidateMoves()
            .filter(dir => opt.acceptMove(dir, state));

        if (candidates.length > 0) {
            // Explore a new state
            const dir = opt.selectMove(candidates, state);
            stack.push(state);
            state = state.applyMove(dir);
        } else if (stack.length > 0) {
            // Backtracking
            state = stack.pop();
        } else {
            throw new Error('No remaining states to explore');
        }
        iteration++;
    }
    return state;
}

function manhattanDistance(p1, p2) {
    return Math.abs(p2.x - p1.x) + Math.abs(p2.y - p1.y);
}

export function generateLevel() {
    const minMoves = 10;
    const maxMoves = 60;
    const minBoxes = 3;
    const maxBoxes = 3;
    const maxWidth = 6;
    const maxHeight = 6;
    const maxCells = 4 * 4;
    const maxConsecutiveNonPushMoves = 8;
    const maxIterations = 200000;

    let state = null;

    while (!state) {
        state = generate({
            acceptSolution: state => {
                return state.moveCount >= minMoves &&
                    state.boxCount >= minBoxes &&
                    state.boxes.every(box => manhattanDistance(box, box.goal) >= 3);
            },
            acceptMove: (dir, state) => {
                return state.moveCount <= maxMoves &&
                    state.boxCount <= maxBoxes &&
                    state.width <= maxWidth &&
                    state.height <= maxHeight &&
                    state.cellCount <= maxCells &&
                    state.moves.slice(state.moves.length - maxConsecutiveNonPushMoves).some(dir => state.isMovePush(dir));
            },
            maxIterations: maxIterations,
            debugIterations: 100000
        });
    }

    console.log('');
    console.log('Level generated:');
    console.log(state.toXSB());
    console.log(state.solution);
    return state.encodeRLE(state.toXSB().replace(/\n/g, '|'));
}