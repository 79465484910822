<script>
    import { onMount, createEventDispatcher } from 'svelte';
    import Spinner from './spinner.svelte';
    import HUD from './hud.svelte';
    import Joystick from './joystick.svelte';
    import Modal from './modal.svelte';

    let level;
    let loading = true;
    let complete = false;
    let container;
    let app;
    export let movecount = 0;
    export let snapshot = '';

    const dispatch = createEventDispatcher();

    export function start(data, s) {
        // If the game has not been loaded yet, it will start after "gameload" event
        level = data;
        if (!loading) {
            complete = false;
            movecount = 0;
            app.game.start(level);
            if (s && s.length > 0) {
                app.game.reset(s);
                movecount = app.game.moveCount;
                snapshot = s;
            }
        } else {
            snapshot = s;
        }
    }

    export function reset() {
        complete = false;
        movecount = 0;
        app.game.reset();
    }

    onMount(() => {
        // Dynamically load the PIXI application for code splitting
        import('../game/gameapp.ts').then(({ default: GameApp }) => {
            app = new GameApp({
                resizeTo: container
            });
            container.appendChild(app.view);

            // Setup game events before loading assets
            app.game.on('gamestart', () => dispatch('start'));
            app.game.on('gameexit', () => dispatch('exit'));
            ['gamemove', 'gameundo', 'gameredo'].forEach(type => {
                app.game.on(type, () => {
                    movecount = app.game.moveCount;
                    snapshot = app.game.snapshot;
                    localStorage.setItem('snapshot', snapshot);
                });
            });
            app.game.on('gamecomplete', () => {
                movecount = app.game.moveCount;
                complete = true;
                dispatch('complete', {
                    movecount: movecount
                });
            });
            app.game.on('gameload', () => {
                loading = false;
                if (level) {
                    start(level, snapshot);
                }
            });

            // Load assets
            app.loader.load();
        });
    });
</script>

<style>
    .game,
    .hud {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .game {
        background-color: #5060d0;
    }
    .hud {
        pointer-events: none;
        opacity: 0.66;
    }
</style>

<div class="game" bind:this="{container}"></div>

<div class="hud">
    <HUD movecount="{movecount}"
        on:menu="{() => dispatch('exit')}"
        on:undo="{() => app.game.undo()}"
        on:redo="{() => app.game.redo()}" />
    <Joystick on:move="{event => app.game.move(event.detail)}" />
</div>

{#if loading}
    <Spinner/>
{/if}

{#if complete}
    <Modal movecount="{movecount}"
        on:reset="{reset}"
        on:menu="{() => dispatch('exit')}"
        on:next="{() => dispatch('next')}"/>
{/if}