<script>
    import { createEventDispatcher } from 'svelte';
    import Bar from './bar.svelte';
    import Button from './button.svelte';

    export let movecount = 0;

    const dispatch = createEventDispatcher();
</script>

<style>
    .content {
        color: white;
        font-size: 24px;
        text-align: center;
        margin: 16px;
        pointer-events: none;
    }
    .icon {
        width: 32px;
        height: 32px;
    }
</style>

<Bar horizontal top left>
    <Button on:click="{() => dispatch('menu')}">
        <img class="icon" src="icons/menu.png" alt="Menu">
    </Button>
</Bar>

<Bar horizontal top center>
    <div class="content">Moves: {movecount}</div>
</Bar>

<Bar horizontal bottom left>
    <Button on:pointerdown="{() => dispatch('undo')}">
        <img class="icon" src="icons/undo.png" alt="Undo">
    </Button>
    <Button on:pointerdown="{() => dispatch('redo')}">
        <img class="icon" src="icons/redo.png" alt="Redo">
    </Button>
</Bar>