<script>
    import { createEventDispatcher, onMount } from 'svelte';

    let joystick;
    let shiftX;
    let shiftY;
    let disabled = false;
    let dragging = false;
    let timeout = null;

    const dispatch = createEventDispatcher();

    // Coordinates are from bottom-right
    function moveTo(x, y) {
        const parentRect = joystick.parentElement.getBoundingClientRect();
        const rect = joystick.getBoundingClientRect();
        x = Math.max(0, Math.min(x, parentRect.width - rect.width));
        y = Math.max(0, Math.min(y, parentRect.height - rect.height));
        joystick.style.right = x.toString() + 'px';
        joystick.style.bottom = y.toString() + 'px';
    }

    function onCenterPointerDown(event) {
        event = event || window.event;
        event.preventDefault();
        const rect = joystick.getBoundingClientRect();
        shiftX = event.clientX - rect.right;
        shiftY = event.clientY - rect.bottom;
        document.addEventListener('pointermove', onPointerMove);
    }

    function onCenterPointerMove(event) {
        event = event || window.event;
        event.preventDefault();
        dragging = true;
        const rect = joystick.parentElement.getBoundingClientRect();
        const x = rect.right - event.pageX + shiftX;
        const y = rect.bottom - event.pageY + shiftY;
        moveTo(x, y);
    }

    function onCenterPointerUp(event) {
        if (dragging) {
            document.removeEventListener('pointermove', onPointerMove);
            dragging = false;
        } else {
            disabled = !disabled;
        }
    }

    function onArrowPointerDown(event, dir) {
        event.preventDefault();
        event.stopPropagation();
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        if (!dragging) {
            if (dir) {
                dispatch('move', dir);
                timeout = setTimeout(() => onArrowPointerRepeat(event, dir), 500);
            } else {
                disabled = !disabled;
            }
        }
    }

    function onArrowPointerRepeat(event, dir) {
        dispatch('move', dir);
        timeout = setTimeout(() => onArrowPointerRepeat(event, dir), 150);
    }

    function onArrowPointerUp(event) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
    }

    function onResize(event) {
        moveTo(4, 4);
    }

    onMount(async () => {
        window.addEventListener('resize', onResize);
        moveTo(4, 4);
    });
</script>

<style>
    .joystick {
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 172px;
        height: 172px;
        pointer-events: none;
        user-select: none;
    }
    .up,
    .down,
    .left,
    .right {
        position: absolute;
        display: flex;
    }
    .up,
    .down {
        left: 0;
        right: 0;
        justify-content: center;
    }
    .up {
        top: 0;
    }
    .down {
        bottom: 0;
    }
    .left,
    .right {
        top: 0;
        bottom: 0;
        align-items: center;
    }
    .left {
        left: 0;
    }
    .right {
        right: 0;
    }
    .center {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: grab;
    }
    .round {
        display: block;
        width: 56px;
        height: 56px;
        border-radius: 28px;
        background-color: white;
        pointer-events: auto;
    }
    .button {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 12px;
        width: 56px;
        height: 56px;
        font-size: 32px;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
        color: white;
        background-color: #60c060;
        border: none;
        box-shadow: inset -4px -4px 0 0 #40a040;
        outline: none;
        pointer-events: auto;
    }
    .button:before,
    .button:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .button:before {
        top: -4px;
        left: 0;
        border-top: 4px solid black;
        border-bottom: 4px solid black;
    }
    .button:after {
        top: 0;
        left: -4px;
        border-left: 4px solid black;
        border-right: 4px solid black;
    }
    .dragging,
    .dragging .round {
        cursor: grabbing;
    }
    .disabled .up,
    .disabled .down,
    .disabled .left,
    .disabled .right {
        display: none;
    }
</style>

<div class="joystick" bind:this="{joystick}"
    class:dragging="{dragging}"
    class:disabled="{disabled}"
    on:contextmenu|preventDefault="{() => false}">
        <div class="center">
            <div class="round"
                on:dragstart="{() => false}"></div>
        </div>
        <div class="up">
            <button class="button"
                on:pointerdown="{event => onArrowPointerDown(event, 'u')}"
                on:pointerup="{onArrowPointerUp}"
                on:pointerout="{onArrowPointerUp}"
                on:pointerleave="{onArrowPointerUp}"
                on:pointercancel="{onArrowPointerUp}">▲</button>
        </div>
        <div class="left">
            <button class="button"
                on:pointerdown="{event => onArrowPointerDown(event, 'l')}"
                on:pointerup="{onArrowPointerUp}"
                on:pointerout="{onArrowPointerUp}"
                on:pointerleave="{onArrowPointerUp}"
                on:pointercancel="{onArrowPointerUp}">◀</button>
        </div>
        <div class="right">
            <button class="button"
                on:pointerdown="{event => onArrowPointerDown(event, 'r')}"
                on:pointerup="{onArrowPointerUp}"
                on:pointerout="{onArrowPointerUp}"
                on:pointerleave="{onArrowPointerUp}"
                on:pointercancel="{onArrowPointerUp}">▶</button>
        </div>
        <div class="down">
            <button class="button"
                on:pointerdown="{event => onArrowPointerDown(event, 'd')}"
                on:pointerup="{onArrowPointerUp}"
                on:pointerout="{onArrowPointerUp}"
                on:pointerleave="{onArrowPointerUp}"
                on:pointercancel="{onArrowPointerUp}">▼</button>
        </div>
</div>