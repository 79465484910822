<script>
    import { createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';

    export let index = null;
    export let data = null;
    export let generator = null;
    export let unlocked = false;
    export let current = false;

    const dispatch = createEventDispatcher();

    function onClick() {
        dispatch('select', {
            index: index,
            data: generator ? generator() : data
        });
    }
</script>

<style>
    .button {
        display: inline-block;
        font-weight: bold;
        font-size: 32px;
        margin: 4px;
        padding: 8px;
        width: 72px;
        height: 72px;
        line-height: 48px;
        text-align: center;
        color: #404040;
        background-color: transparent;
        border: dotted 4px #a0a0a0;
        pointer-events: none;
    }
    .button.unlocked {
        padding: 4px;
        border: solid 4px #404040;
        background-color: #60a060;
        background: linear-gradient(to bottom right, #408040, #40c040);
        cursor: pointer;
        transition: all .5s ease;
        pointer-events: auto;
    }
    .button.current {
        background-color: #40a0f0;
        background: linear-gradient(to bottom right,#4060f0,#60c0f0);
    }
    .button.unlocked:hover {
        box-shadow: 2px 2px 4px gray;
    }
    .button img {
        width: 32px;
        height: 32px;
        padding: 12px;
    }
</style>

<button class="button" class:unlocked="{unlocked}" class:current="{current}"
    on:click="{onClick}"
    in:fade="{{delay: 15 * index, duration: 250}}">
        {#if index !== null}
            {index+1}
        {:else}
            <img src="icons/next.png" alt="Play">
        {/if}
</button>